<template>
  <header class="header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <nav
            class="navbar navbar-expand-md navbar-dark pl-lg-0"
            v-if="current_user && current_user.role != 'visitor'"
          >
            <a class="navbar-brand header-text" href="javascript:void(0);">WED WITH US</a>
            <div
              class="navbar-list"
              v-if="current_user && current_user.role === 'admin'"
            >
              <router-link
                tag="a"
                class="text-yellow hover-yellow mr-3 mr-md-5"
                to="/admin/profile"
                >Profile</router-link
              >
              <router-link
                tag="a"
                class="text-yellow hover-yellow mr-3 mr-md-5"
                to="/admin/event"
                >Event</router-link
              >
              <router-link
                tag="a"
                class="text-yellow hover-yellow mr-3 mr-md-5"
                to="/admin/speakers"
                >Speakers</router-link
              >
            </div>
            <div
              class="navbar-list"
              v-if="current_user && current_user.role === 'speaker'"
            >
              <router-link
                tag="a"
                class="text-yellow hover-yellow mr-3 mr-md-5"
                to="/speaker/profile"
                >Profile</router-link
              >
              <router-link
                tag="a"
                class="text-yellow hover-yellow mr-3 mr-md-5"
                to="/speaker/live"
                >Live</router-link
              >
            </div>
            <a
              href="#"
              class="text-yellow hover-yellow logout"
              @click="logout"
              v-if="current_user && current_user.role != 'visitor'"
              ><i class="fas fa-sign-out-alt"></i>Logout</a
            >
          </nav>
          <nav
            class="navbar navbar-expand-md navbar-dark justify-content-start"
            v-if="current_user && current_user.role === 'visitor'"
          >
            <a class="navbar-brand" href="javascript:void(0);">{{
              wed_name
            }}</a>
          </nav>
          <nav
            class="navbar navbar-expand-md navbar-dark justify-content-center"
            v-if="!current_user"
          >
            <a class="navbar-brand header-text" href="javascript:void(0);">WED WITH US</a>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getApiManager } from "../api";
import { apiBaseUrl,liveBaseUrl } from "../constants/config";
export default {
  data() {
    return {
      current_user: JSON.parse(localStorage.getItem("loginInfo")),
     
      wed_name: localStorage.getItem("wed_name")
        ? localStorage.getItem("wed_name")
        : "WED WITH US",
    };
  },
  mounted() {
    console.log('77777777777',localStorage.getItem("wed_name"))
      this.connection = new WebSocket(liveBaseUrl);
  },
  methods: {
    ...mapActions(["signOut"]),
    logout() {
      if (
        localStorage.getItem("wed_name") != undefined &&
        localStorage.getItem("wed_name") != ""
      ) {
        getApiManager()
          .post(`${apiBaseUrl}/api/vonage/RemoveSession`, {
            wed_name: this.wed_name,
          })
          .then((result) => {
            if (result.data.success == true) {
              this.connection.send(
                JSON.stringify({
                  type: "end_live",
                })
              );
              if (self.OTSession) {
                self.OTSession.disconnect();
              }
              localStorage.setItem("Live_id", null);
              localStorage.setItem("deceased_name",'')
              this.signOut();
              this.$router.push("/login");
            }
          });
      } else {
        if (self.OTSession) {
          self.OTSession.disconnect();
        }
        localStorage.setItem("Live_id", null);
        localStorage.setItem("deceased_name",'')
        this.signOut();
        this.$router.push("/login");
        // window.location.reload();
      }
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

.header-text{
  font-family: 'Playfair Display', serif;
  font-size: 36px;
}
.navbar{
  padding: 0;
}

</style>

